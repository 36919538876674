// Shorthand Version.
import tw from "twin.macro";

const BaseButton = tw.button`
  bg-white hover:bg-transparent
  font-header
  text-black text-sm hover:text-white
  transition duration-500 
  p-2 px-4 
  border-2 border-white
  rounded-3xl
`;

export default BaseButton;
